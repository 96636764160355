.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  max-height: 375px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.slick-prev::before {
  content: url("../../assets/icons/arrow-left.png") !important;
  margin-top: -4px;
}

.slick-next::before {
  content: url("../../assets/icons/arrow-right.png") !important;
  margin-top: -4px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
